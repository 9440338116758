import React from "react";
import withNavigation from "../../util/withNavigation";
import './MatchedTalentList.css';
import { MatchedTalentStatus, getMatchedTalentInterviewTotal, getMathcedTalentStatus } from "../../common/common";
import { WithTranslation, withTranslation } from "react-i18next";
import { BsLock, BsUnlock } from "react-icons/bs";

/**
 * 组件-案件匹配的要员列表
 */
class MatchedTalentList extends React.Component<MatchedTalentListProps, MatchedTalentListState> {
    constructor(props:MatchedTalentListProps) {
        super(props)
    }

    render(): React.ReactNode {
        const { t } = this.props
        const list = this.props.list || []
        const isPremiumUser = this.props.isPremiumUser
        const matchedList = list.map((item:any) => {
            // console.log(item)
            // 匹配id
            const mid = item.id
            // 匹配的要员id
            // const talentId = item.talentId
            // 匹配的要员概要
            const talentBrief = item.talentBrief
            // 匹配类型
            const matchedType = item.matchedType
            // 匹配度
            const matchedPercent = item.matchedPercent
            // 去掉%百分号只取数值部分（存在小数点）
            const matchedPercentNum = Number(matchedPercent.replace(/%/g, ''))
            // 匹配的要员关系状态
            const matchedTalentStatus = item.matchedTalentStatus
            const matchedTalentStatusStr = getMathcedTalentStatus(matchedType, matchedTalentStatus, true, t)
            // 匹配的要员已完成的面试数量
            const talentInterviewComplete = item.talentInterviewComplete
            // 匹配的要员尚未开始的面试数量
            const talentInterviewComing = item.talentInterviewComing
            const totalStr = getMatchedTalentInterviewTotal(talentInterviewComplete, talentInterviewComing)
            const isClosed = (matchedTalentStatus == MatchedTalentStatus.CLOSED) || (matchedTalentStatus == MatchedTalentStatus.STOP)
            // 是否已解锁
            const isUnlock = item.isUnlock || isPremiumUser
            // 大于90%加粗字体
            const isBold = matchedPercentNum.valueOf() >= Number(90).valueOf()
            return(
                <div className={`talent-item ${isClosed ? 'closed' : '' }`} style={{fontWeight: `${isBold ? 'bolder' : ''} `}} key={mid} onClick={()=>this.onTalentClick(mid)}>
                    {!isUnlock ?  <BsLock color="burlywood"/> : <BsUnlock color="burlywood" />}
                    {matchedTalentStatusStr && <div className="status">{matchedTalentStatusStr}</div>}
                    <div className="brief" title={talentBrief + ' ' + matchedPercent}>{talentBrief}</div>
                    <div className="total">{totalStr}</div>
                </div>
            )
        })

        return(
            <div className="matched-talent-container">
                <div className="title">{t('elements.case.MatchedTalentList.124369-0')}</div>
                {matchedList.length ? <div>{matchedList}</div> : <span>{t('elements.case.MatchedTalentList.124369-1')}</span>}
            </div>
        )
    }

    /**
     * 案件匹配的要员点击事件
     * 
     * @param matchId 匹配id
     */
    onTalentClick(matchId: string): void {
        // 跳转到他社要员详情页面
        const navigate = this.props.navigate
        navigate('/case-matched-talent?id=' + matchId)
    }

}

interface MatchedTalentListProps extends WithTranslation {
    // 当前案件
    case: any
    // 案件匹配的要员列表
    list?: []
    // 当前用户是否为付费用户
    isPremiumUser: boolean
    // 导航
    navigate: any
}

interface MatchedTalentListState {
    
}

export default withTranslation()(withNavigation(MatchedTalentList));